//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { orderBy } from "~/util/helpers";
import CategoryProduct from "~/components/products/CategoryProduct.vue";
import { sendYandexMetric } from "~/service/ecommerce";
import cityLinksHelper from "~/util/city-links-helper";

export default {
    name: "CategoryCatalog",
    components: {
        CategoryProduct,
        Swiper,
        SwiperSlide,
    },
    props: ["category"],
    data() {
        return {
            swiperOption: {
                slidesPerView: "auto",
                spaceBetween: 0,
                freeMode: true,
                pagination: { clickable: true },
            },
        };
    },
    computed: {
        ...mapState("products", [
            "products",
            "currentViewCategoryId",
            "currentSort",
            "anotherMenu",
            "catalogView",
            "activeGeneralTags",
        ]),
        ...mapState("metrika", ["paramsAB"]),
        // ...mapGetters("cart", ["closestDepartments"]),
        checkCatalogView() {
            return this.catalogView.view === "list" && this.oneColumnCatalog;
        },
        oneColumnCatalog() {
            return this.catalogView.screenWidth <= 580;
        },
        generalTags() {
            return this.category.tags.filter((tag) => tag.is_general);
        },
        isCurrentCategory() {
            return this.currentViewCategoryId === this.category.id;
        },
        uniqueNameProducts() {
            return this.products.filter((product, index, self) => {
                return index === self.findIndex((p) => p.name === product.name);
            });
        },
        productsByCategory() {
            return this.uniqueNameProducts.filter((item) => {
                return item.categories_ids?.includes(this.category.id);
            });
        },
        needFilterProducts() {
            return !!this.activeTagByCategory;
        },
        activeTagByCategory() {
            return this.activeGeneralTags.find((tag) => tag.categoryId === this.category.id);
        },
        filtredProducts() {
            return this.productsByCategory.filter((product) => {
                return product.tags.includes(this.activeTagByCategory.tagId);
            });
        },
        sortedProducts() {
            let productsArr = this.needFilterProducts ? this.filtredProducts : this.productsByCategory;

            const sortType = this.currentSort;
            const generalTagsByPopularity = this.generalTagsByPopularity;

            if (sortType === "popularity") {
                productsArr = productsArr.sort((a, b) => {
                    const tagA = generalTagsByPopularity.find((generalTag) =>
                        a.tags.some((tag) => tag === generalTag.id)
                    );
                    const tagB = generalTagsByPopularity.find((generalTag) =>
                        b.tags.some((tag) => tag === generalTag.id)
                    );

                    if (!tagA) {
                        return 1;
                    }

                    if (!tagB) {
                        return -1;
                    }

                    if (tagA.id === tagB.id) {
                        return b.rating - a.rating;
                    }

                    return tagB.rating - tagA.rating;
                });
                // productsArr = orderBy(
                //     productsArr,
                //     'rating',
                //     'desc',
                // );
            } else if (sortType === "priceUp") {
                productsArr = orderBy(productsArr, "price", "asc");
            } else if (sortType === "priceDown") {
                productsArr = orderBy(productsArr, "price", "desc");
            } else if (sortType === "new") {
                productsArr = orderBy(productsArr, "sort_order", "desc");
            } else if (sortType === "evaluation") {
                const productWithScore = orderBy(
                    productsArr.filter((item) => item.scores_count > 10),
                    "avg_score",
                    "desc"
                );
                const productWithoutScore = productsArr.filter(
                    (item) => !item.scores_count || item.scores_count <= 10
                );
                productsArr = productWithScore.concat(productWithoutScore);
            } else if (sortType === "personal_score") {
                productsArr = orderBy(productsArr, "personal_score", "desc");
            }

            const bannerCard = productsArr.find((elem) => elem.is_banner_card);
            if (bannerCard) {
                return [bannerCard, ...productsArr.filter((elem) => elem !== bannerCard)];
            }

            return productsArr;
        },
        categoryTitle() {
            const title = this.category.title_h1 || this.category.title;

            return this.category.id !== this.anotherMenu
                ? title
                : title.replace(/у/gi, "<span class='revert'>у</span>");
        },
        notEmptyGeneralTags() {
            return this.generalTags.filter((tag) =>
                this.productsByCategory.some((product) => product.tags?.includes(tag.id))
            );
        },
        generalTagsByPopularity() {
            const tagsWithRaiting = this.notEmptyGeneralTags.map((tag) => {
                const productsByTag = this.productsByCategory.filter((product) => product.tags.includes(tag.id));

                const rating = productsByTag.length
                    ? productsByTag.reduce((accum, curr) => accum + curr.rating, 0)
                    : 0;

                return {
                    ...tag,
                    rating: Math.round(rating),
                };
            });

            return tagsWithRaiting.sort((a, b) => b.rating - a.rating);
        },
        sortedGeneralTags() {
            if (this.generalTags.length < 2) {
                return [];
            }

            if (this.notEmptyGeneralTags.length < 2) {
                return [];
            }

            if (this.currentSort === "popularity") {
                return this.generalTagsByPopularity;
            }

            return this.notEmptyGeneralTags;
        },
        currentTagItem() {
            return this.getTagItemByRoutePath(this.$route.path);
        },
        getCategoryPath() {
            return this.$catalogRouter.getCategoryPath(this.category);
        },
    },
    methods: {
        showMiniCart(ev) {
            this.$emit("showMiniCart", ev);
        },
        hideMiniCart() {
            this.$emit("hideMiniCart");
        },
        toggleMiniCart(ev) {
            this.$emit("toggleMiniCart", ev);
        },
        tagLink(tag) {
            return this.$catalogRouter.getTagPath(tag.id, this.category.id);
        },
        getTagItemByRoutePath(path) {
            return this.$catalogRouter.getItemByPath(cityLinksHelper.cleanCityPath(path));
        },
        handleGeneralTags(tag) {
            this.$store.dispatch("products/handleActiveGeneralTag", {
                tagId: tag.id,
                categoryId: this.category.id,
            });
            if (this.isActiveTag(tag.id)) {
                this.$router.push(this.tagLink(tag));
            } else {
                this.$router.push(this.getCategoryPath);
            }
        },
        isActiveTag(tagId) {
            return this.activeGeneralTags.find(
                (item) => item.tagId === tagId && item.categoryId === this.category.id
            );
        },
    },
};
